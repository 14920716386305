exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-botoxclinic-js": () => import("./../../../src/pages/botoxclinic.js" /* webpackChunkName: "component---src-pages-botoxclinic-js" */),
  "component---src-pages-cataract-js": () => import("./../../../src/pages/cataract.js" /* webpackChunkName: "component---src-pages-cataract-js" */),
  "component---src-pages-computervisionsyndrome-js": () => import("./../../../src/pages/computervisionsyndrome.js" /* webpackChunkName: "component---src-pages-computervisionsyndrome-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cornealdieseases-js": () => import("./../../../src/pages/cornealdieseases.js" /* webpackChunkName: "component---src-pages-cornealdieseases-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-glaucoma-js": () => import("./../../../src/pages/glaucoma.js" /* webpackChunkName: "component---src-pages-glaucoma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-keratoconus-js": () => import("./../../../src/pages/keratoconus.js" /* webpackChunkName: "component---src-pages-keratoconus-js" */),
  "component---src-pages-nethraoptics-js": () => import("./../../../src/pages/nethraoptics.js" /* webpackChunkName: "component---src-pages-nethraoptics-js" */),
  "component---src-pages-news-and-events-js": () => import("./../../../src/pages/news_and_events.js" /* webpackChunkName: "component---src-pages-news-and-events-js" */),
  "component---src-pages-oculoplasty-js": () => import("./../../../src/pages/oculoplasty.js" /* webpackChunkName: "component---src-pages-oculoplasty-js" */),
  "component---src-pages-ourdoctors-js": () => import("./../../../src/pages/ourdoctors.js" /* webpackChunkName: "component---src-pages-ourdoctors-js" */),
  "component---src-pages-ourinstitution-js": () => import("./../../../src/pages/ourinstitution.js" /* webpackChunkName: "component---src-pages-ourinstitution-js" */),
  "component---src-pages-ourteam-js": () => import("./../../../src/pages/ourteam.js" /* webpackChunkName: "component---src-pages-ourteam-js" */),
  "component---src-pages-pediatricopthalmology-js": () => import("./../../../src/pages/pediatricopthalmology.js" /* webpackChunkName: "component---src-pages-pediatricopthalmology-js" */),
  "component---src-pages-rajan-charitable-trust-js": () => import("./../../../src/pages/rajan_charitable_trust.js" /* webpackChunkName: "component---src-pages-rajan-charitable-trust-js" */),
  "component---src-pages-rajan-eye-bank-js": () => import("./../../../src/pages/rajan_eye_bank.js" /* webpackChunkName: "component---src-pages-rajan-eye-bank-js" */),
  "component---src-pages-refractive-surgery-js": () => import("./../../../src/pages/refractive_surgery.js" /* webpackChunkName: "component---src-pages-refractive-surgery-js" */),
  "component---src-pages-retina-js": () => import("./../../../src/pages/retina.js" /* webpackChunkName: "component---src-pages-retina-js" */),
  "component---src-pages-smile-procedure-js": () => import("./../../../src/pages/smile_procedure.js" /* webpackChunkName: "component---src-pages-smile-procedure-js" */),
  "component---src-pages-strabismus-js": () => import("./../../../src/pages/strabismus.js" /* webpackChunkName: "component---src-pages-strabismus-js" */),
  "component---src-pages-vitreoretina-js": () => import("./../../../src/pages/vitreoretina.js" /* webpackChunkName: "component---src-pages-vitreoretina-js" */)
}

